import axios                                                                        from '@/api/api';
import { AccountAddressListResponse }                                               from '@/types/account';
import { AddressCreatePayload, AddressResponse, AddressType, AddressUpdatePayload } from '@/types/address';
import getRoute                                                                     from '@/utils/routing';

class AddressApi {
    async getAddresses() {
        return axios.get<AccountAddressListResponse>(getRoute('api_v1_account_address_list'));
    }

    async storeAddress(payload: AddressCreatePayload, addressType: AddressType) {
        return axios.post<AddressResponse>(
            getRoute('api_v1_address_store_address', { checkoutType: addressType }),
            payload,
        );
    }

    async updateAddress(payload: AddressUpdatePayload, addressType: AddressType) {
        return axios.put<AddressResponse>(
            getRoute('api_v1_address_store_address', { checkoutType: addressType }),
            payload,
        );
    }

    async deleteAddress(addressId: number) {
        return axios.delete(
            getRoute(
                'api_v1_address_delete_address',
                { id: addressId },
            ),
        );
    }
}

export default (new AddressApi());
