import { AxiosError }  from 'axios';
import { defineStore } from 'pinia';
import { ref }         from 'vue';

import AddressApi                                                           from '@/api/address-api';
import { Address, AddressCreatePayload, AddressType, AddressUpdatePayload } from '@/types/address';
import { captureError }                                                     from '@/utils/error-logger';

const useAddressStore = defineStore('address', () => {
    const addresses = ref<Address[]>([]);
    const defaultAddress = ref<Address | null>(null);
    const deliveryAddresses = ref<Address[]>([]);
    const invoiceAddresses = ref<Address[]>([]);

    function reset(): void {
        addresses.value = [];
        defaultAddress.value = null;
        deliveryAddresses.value = [];
        invoiceAddresses.value = [];
    }

    /**
     * Fetches the addresses from backend, set store value and return address list value
     * Additionally populates the deliveryAddresses, invoiceAddresses and defaultAddress refs to provide a better API
     */
    async function fetchAddresses(): Promise<Address[]> {
        return AddressApi.getAddresses()
            .then(response => {
                addresses.value = response.data;
                deliveryAddresses.value = [];
                invoiceAddresses.value = [];

                addresses.value.forEach(address => {
                    switch (address.addressType) {
                        case AddressType.DEFAULT:
                            defaultAddress.value = address;
                            deliveryAddresses.value.push(address);
                            invoiceAddresses.value.push(address);
                            break;
                        case AddressType.DELIVERY:
                            deliveryAddresses.value.push(address);
                            break;
                        case AddressType.INVOICE:
                            invoiceAddresses.value.push(address);
                            break;
                    }
                });

                return addresses.value;
            })
            .catch((error: AxiosError) => {
                addresses.value = [];
                defaultAddress.value = null;
                deliveryAddresses.value = [];
                invoiceAddresses.value = [];

                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Only fetches if the store value is null and return the address list value
     */
    async function getAddresses(): Promise<Address[]> {
        if (addresses.value.length === 0) {
            await fetchAddresses();
        }

        return new Promise(resolve => resolve(addresses.value));
    }

    /**
     * Only fetches if address list store value is null and returns the default address value
     */
    async function getDefaultAddress(force = false): Promise<Address | null> {
        if (addresses.value.length === 0 || force) {
            await fetchAddresses();
        }

        return new Promise(resolve => resolve(defaultAddress.value));
    }

    /**
     * Only fetches if address list store value is null and returns the delivery address list value
     */
    async function getDeliveryAddresses(force = false): Promise<Address[]> {
        if (addresses.value.length === 0 || force) {
            await fetchAddresses();
        }

        return new Promise(resolve => resolve(deliveryAddresses.value));
    }

    /**
     * Only fetches if address list store value is null and returns the invoice address list value
     */
    async function getInvoiceAddresses(force = false): Promise<Address[]> {
        if (addresses.value.length === 0 || force) {
            await fetchAddresses();
        }

        return new Promise(resolve => resolve(invoiceAddresses.value));
    }

    /**
     Proxy method to allow calling get*Addresses based on variable
     */
    async function getAddressesOfType(type: AddressType, force = false): Promise<Address[]> {
        if (type === AddressType.INVOICE) {
            return getInvoiceAddresses(force);
        }

        return getDeliveryAddresses(force);
    }

    /**
     * Store new address, fetch addresses from the backend and return the response
     */
    async function storeAddress(payload: AddressCreatePayload, addressType: AddressType) {
        return AddressApi.storeAddress(payload, addressType)
            .then(async response => {
                await fetchAddresses();

                return response;
            });
    }

    /**
     * Update existing address, fetch addresses from backend and return the response
     */
    async function updateAddress(payload: AddressUpdatePayload, addressType: AddressType) {
        return AddressApi.updateAddress(payload, addressType)
            .then(async response => {
                await fetchAddresses();

                return response;
            });
    }

    /**
     * Delete existing address, fetch addresses from backend and return the response
     */
    async function deleteAddress(addressId: number) {
        return AddressApi.deleteAddress(addressId)
            .then(async response => {
                await fetchAddresses();

                return response;
            });
    }

    /**
     * Expose store values and methods
     */
    return {
        addresses,
        defaultAddress,
        deliveryAddresses,
        invoiceAddresses,
        reset,
        fetchAddresses,
        getAddresses,
        getDefaultAddress,
        getDeliveryAddresses,
        getInvoiceAddresses,
        getAddressesOfType,
        storeAddress,
        updateAddress,
        deleteAddress,
    };
});

export default useAddressStore;
